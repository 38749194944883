@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* layout */
  :root {
    --layout-max-w: 520px;
    --layout-min-h: 100dvh;
  }
  .layout {
    @apply max-w-[var(--layout-max-w)] min-h-[var(--layout-min-h)] w-full mx-auto;
  }
}

::-webkit-scrollbar {
  /* 가로 스크롤바 보이지 않도록 */
  display: none;
}
